import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AuthenticationService } from './shared/services/authentication.service';
import { UserDataService } from './shared/services/userData.service';

// Extend Window interface for TypeScript
declare global {
  interface Window {
    Cypress?: any;
    authService?: AuthenticationService;
    userDataService?: UserDataService;
    rolesService?: any;
  }
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SharedModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    // Expose services for Cypress testing
    if (typeof window !== 'undefined') {
      window.authService = this.authService;
      window.userDataService = this.userDataService;
    }
  }
  
  title = 'interno';
}
