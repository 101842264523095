import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { importProvidersFrom } from '@angular/core';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideZoneChangeDetection } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { provideHttpClient } from '@angular/common/http';
import { ButacoPreset } from '../assets/styles/ButacoPreset';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    importProvidersFrom(AngularFireModule.initializeApp(environment.firebase)),
    provideAnimationsAsync(),
    provideAuth(() => getAuth()),
    provideHttpClient(),
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
    providePrimeNG({ 
      theme: {
          preset: ButacoPreset
      }
  })
  ]
};